import OrdersAPI from '../../../api/orders';
import types from '../../mutation-types';

export const actions = {
    get: async ({ commit }, params) => {
        commit(types.SET_ORDERS_UI_FLAG, { isFetching: true });
        try {
            const {
                data: {
                    data,
                    meta
                },
            } = await OrdersAPI.get(params);

            commit(types.CLEAR_ORDERS);
            commit(types.SET_ORDERS, data);
            commit(types.SET_ORDERS_META, meta);
            commit(types.SET_ORDERS_UI_FLAG, { isFetching: false });
        } catch (error) {
            console.error(error)
            commit(types.SET_ORDERS_UI_FLAG, { isFetching: false });
        }
    },
}