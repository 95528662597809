export const getters = {
    getToken($state) {
        return $state.token
    },
    getUserData($state) {
        return $state.userData
    },
    getApiUrl($state) {
        return $state.apiUrl
    },
    getWorkOrder($state) {
        return $state.workOrder
    },
    getUiFlags($state) {
        return $state.uiFlags
    }
}