import types from "../../mutation-types";
import Vue from 'vue';

export const mutations = {
    [types.SET_ORDERS_UI_FLAG]($state, data) {
        $state.uiFlags = {
            ...$state.uiFlags,
            ...data,
        };
    },
    [types.CLEAR_ORDERS]: $state => {
        Vue.set($state, 'records', {});
    },
    [types.SET_ORDERS_META]: ($state, data) => {
        const {
            current_page: currentPage,
            from,
            last_page: lastPage,
            per_page: perPage,
            to,
            total
        } = data;

        Vue.set($state.meta, 'total', total);
        Vue.set($state.meta, 'currentPage', currentPage);
        Vue.set($state.meta, 'from', from);
        Vue.set($state.meta, 'lastPage', lastPage);
        Vue.set($state.meta, 'perPage', perPage);
        Vue.set($state.meta, 'to', to);
    },
    [types.SET_ORDERS]: ($state, data) => {
        data.forEach(order => {
            Vue.set($state.records, order.id_order, {
                ...($state.records[order.id_order] || {}),
                ...order,
            });
        });
    },
    [types.UPDATE_ORDER]: ($state, primaryActorId) => {
        Object.values($state.records).forEach(item => {
            if (item.primary_actor_id === primaryActorId) {
                Vue.set($state.records[item.id], 'read_at', true);
            }
        });
    },
    [types.UPDATE_ALL_ORDERS]: $state => {
        Object.values($state.records).forEach(item => {
            Vue.set($state.records[item.id], 'read_at', true);
        });
    },

    [types.ADD_ORDER]($state, data) {
        const { notification, unread_count: unreadCount, count } = data;
        Vue.set($state.records, notification.id, {
            ...($state.records[notification.id] || {}),
            ...notification,
        });
        Vue.set($state.meta, 'unreadCount', unreadCount);
        Vue.set($state.meta, 'count', count);
    },
}