<template>
  <div class="w-full flex flex-col min-h-screen">
    <header
      class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800 py-3"
    >
      <div class="flex flex-row justify-between items-center">
        <div class="flex w-full px-4">
          <woot-sidemenu-icon class="m-0" />
          <h1
            class="m-0 ml-3 text-lg text-slate-900 dark:text-slate-100 whitespace-nowrap"
          >
            Managemen Work Order Ku
          </h1>
        </div>
        <div class="flex w-full justify-end mr-4">
          <woot-button
            class="clear"
            color-scheme="success"
            icon="book"
            data-testid="create-new-work-order"
            @click="addWorkOrder"
          >
            Tambah Work Order
          </woot-button>
        </div>
      </div>
    </header>
    <section
      class="conversation-page flex-grow bg-white dark:bg-slate-900 overflow-auto"
    >
      <div class="gap-2 flex flex-row mt-1 mx-2">
        <div class="text-nowrap flex flex-col w-1/2">
          <multiselect
            v-model="selectedStatus"
            :options="statusOptions"
            track-by="key"
            label="label"
            :close-on-select="true"
            :clear-on-select="true"
            placeholder="Pilih Status"
            @select="onSelectedStatus"
            @remove="onRemoveStatus"
          />
        </div>
      </div>
      <div
        v-if="isLoading"
        class="flex justify-center items-center text-center text-slate-900 dark:text-white py-4"
      >
        <spinner /> Sedang memuat data...
      </div>
      <div v-else>
        <div
          v-if="tableData.length === 0"
          class="text-center py-4 text-gray-600"
        >
          Tidak ada data work order.
        </div>
        <div v-else class="complaint-table overflow-auto">
          <ve-table
            max-height="calc(100vh - 12rem)"
            scroll-width="150rem"
            :columns="columns"
            :table-data="tableData"
            :border-around="false"
          />
        </div>
      </div>
    </section>
    <footer
      v-if="isFooterVisible"
      class="bg-white dark:bg-slate-800 h-[60px] border-t border-solid border-slate-75 dark:border-slate-700/50 flex items-center justify-between py-0 px-4"
    >
      <div class="left-aligned-wrap">
        <div class="text-xs text-slate-600 dark:text-slate-200">
          <strong>{{ firstIndex }}</strong> -
          <strong>{{ lastIndex }}</strong> of
          <strong>{{ totalCount }}</strong> items
        </div>
      </div>
      <div class="right-aligned-wrap">
        <div
          v-if="totalCount > 0"
          class="primary button-group pagination-button-group"
        >
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasFirstPage"
            @click="onFirstPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
            <fluent-icon
              icon="chevron-left"
              size="18"
              :class="pageFooterIconClass"
            />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasPrevPage"
            @click="onPrevPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
          </woot-button>
          <woot-button size="small" variant="smooth" color-scheme="secondary">
            {{ currentPage }}
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasNextPage"
            @click="onNextPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="hasLastPage"
            @click="onLastPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
            <fluent-icon
              icon="chevron-right"
              size="18"
              :class="pageFooterIconClass"
            />
          </woot-button>
        </div>
      </div>
    </footer>
    <create-modal :show="showCreateModal" @cancel="closeCreateModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable } from 'vue-easytable';
import CreateModal from './CreateWorkOrder.vue';
import Spinner from 'shared/components/Spinner.vue';

export default {
  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      oaUserId: '',
      searchQuery: '',
      selectedPelanggan: null,
      pelanggan_id: null,
      showCreateModal: false,
      isLoading: false,
      tableData: [],
      selectedStatus: [],
      pelangganList: [],
      statusOptions: [
        { key: 'pending', label: 'Pending' },
        { key: 'active', label: 'Active' },
        { key: 'finish', label: 'Finish' },
        { key: 'cancel', label: 'Cancel' },
        { key: 'validated', label: 'Validated' },
      ],
    };
  },
  components: {
    CreateModal,
    VeTable,
    Spinner,
  },
  computed: {
    ...mapGetters({
      currentUserID: 'getCurrentUserID',
      records: 'contacts/getContacts',
      token: 'openaccess/getToken',
    }),
    pageFooterIconClass() {
      return 'margin-left-minus-slab';
    },
    isFooterVisible() {
      return this.totalCount > 0;
    },
    firstIndex() {
      return this.pageSize * (this.currentPage - 1) + 1;
    },
    lastIndex() {
      return Math.min(this.totalCount, this.pageSize * this.currentPage);
    },
    hasFirstPage() {
      return this.currentPage === 1;
    },
    hasLastPage() {
      return this.currentPage === Math.ceil(this.totalCount / this.pageSize);
    },
    hasNextPage() {
      const lastPage = Math.ceil(this.totalCount / this.pageSize);
      return this.currentPage < lastPage;
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    columns() {
      return [
        {
          field: 'index',
          key: 'index',
          title: 'NO',
          align: 'center',
          width: 50,
          fixed: 'left',
          renderBodyCell: ({ row, rowIndex }) => {
            return this.pageSize * (this.currentPage - 1) + rowIndex + 1;
          },
        },
        {
          field: 'name',
          key: 'name',
          title: 'NAMA JENIS WO',
          align: 'left',
          fixed: 'left',
          width: 150,
          renderBodyCell: ({ row }) => {
            return (
              <div class="row-user">
                <div class="user-block">
                  <p class="wo-type-name">{row.name}</p>
                  <p class="wo-type-description">{row.description}</p>
                </div>
              </div>
            );
          },
        },
        {
          field: 'type',
          key: 'type',
          title: 'TYPE WO',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => {
            return (
              <div class="row-user">
                <div class="user-block">
                  <p class="wo-type-name">{row.type.type}</p>
                </div>
              </div>
            );
          },
        },
        {
          field: 'status',
          key: 'status',
          title: 'STATUS',
          align: 'left',
          width: 120,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                <div class="tags">
                  {row.status === 'pending' && (
                    <span class="pending-tag">Pending</span>
                  )}
                  {row.status === 'active' && (
                    <span class="active-tag">On Progress</span>
                  )}
                  {row.status === 'finish' && (
                    <span class="finish-tag">Finish</span>
                  )}
                  {row.status === 'cancel' && (
                    <span class="cancel-tag">Batal</span>
                  )}
                  {row.status === 'validated' && (
                    <span class="validated-tag">Validated</span>
                  )}
                </div>
              </div>
            </div>
          ),
        },
        {
          field: 'pelanggan',
          key: 'pelanggan',
          title: 'NAMA PELANGGAN',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                {row.pelanggan &&
                row.pelanggan.user &&
                row.pelanggan.user.full_name
                  ? row.pelanggan.user.full_name
                  : '-'}
              </div>
            </div>
          ),
        },
        {
          field: 'staff',
          key: 'staff',
          title: 'STAFF',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                {row.users && row.users.length > 0
                  ? row.users.map(user => user.full_name).join(', ')
                  : 'No Staff Available'}
              </div>
            </div>
          ),
        },
        {
          field: 'start_at',
          key: 'start_at',
          title: 'MULAI',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">{row.start_at}</div>
            </div>
          ),
        },
        {
          field: 'finish_at',
          key: 'finish_at',
          title: 'SELESAI',
          align: 'left',
          width: 150,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">{row.finish_at}</div>
            </div>
          ),
        },
        {
          field: 'estimated_working_time',
          key: 'estimated_working_time',
          title: 'ESTIMASI',
          align: 'left',
          width: 120,
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                {row.estimated_working_time === 0
                  ? '-'
                  : `${row.estimated_working_time} menit`}
              </div>
            </div>
          ),
        },
        {
          field: 'action',
          key: 'action',
          title: 'AKSI',
          align: 'center',
          fixed: 'right',
          width: 50,
          renderBodyCell: ({ row }) => (
            <div>
              <router-link
                to={`/app/accounts/${this.$route.params.accountId}/work-orders/${row.id}/edit`}
              >
                Edit
              </router-link>
            </div>
          ),
        },
      ];
    },
  },
  methods: {
    onSelectedStatus() {
      this.fetchWorkOrders();
    },
    onRemoveStatus() {
      this.selectedStatus = null;
      this.fetchWorkOrders();
    },
    addWorkOrder() {
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    onFirstPage() {
      if (!this.hasFirstPage) {
        this.fetchWorkOrders(1);
      }
    },
    onLastPage() {
      if (!this.hasLastPage) {
        const lastPage = Math.ceil(this.totalCount / this.pageSize);
        this.fetchWorkOrders(lastPage);
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.fetchWorkOrders(this.currentPage + 1);
      }
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.fetchWorkOrders(this.currentPage - 1);
      }
    },
    async fetchUserProfile() {
      try {
        const response = await axios.get(
          `${this.apiUrl}/api/user/account/profile?with=telegram,company`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );

        if (response.status === 200 && response.data.data) {
          this.oaUserId = response.data.data.id;
          return this.oaUserId;
        } else {
          throw new Error('User profile not found');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        return null;
      }
    },
    async fetchWorkOrders(page = 1) {
      this.isLoading = true;
      const apiUrl = `${this.apiUrl}/api/work-order/work-order-users/user/${this.oaUserId}`;
      const today = new Date().toISOString().split('T')[0];
      const params = {
        // start_at: ['2024-10-01', '2025-01-01'],
        date_wo: today,
        company_id: 3,
        per_page: this.pageSize,
        order_by: 'start_at',
        order: 'descending',
        page: page,
        status: this.selectedStatus ? this.selectedStatus.key : '',
      };
      try {
        const response = await axios.get(apiUrl, {
          params: params,
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.tableData = response.data.data;
        this.totalCount = response.data.meta.total;
        this.currentPage = response.data.meta.current_page;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async initPageData() {
      try {
        if (this.token) {
          const userId = await this.fetchUserProfile();
          if (userId) {
            this.fetchWorkOrders();
          }
        }
      } catch (error) {
        console.error('Error initializing data', error);
      }
    },
  },
  mounted() {
    this.initPageData();
  },
};
</script>

<style scoped>
.conversation-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  width: 100%;
}

.complaint-table {
  margin: 0;
  padding: 0;
  width: 100%;
}

.tags {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pending-tag {
  @apply bg-yellow-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.active-tag {
  @apply bg-yellow-400 text-white text-xs py-0.5 px-3 rounded-md;
}

.validated-tag {
  @apply bg-green-800 text-white text-xs py-0.5 px-3 rounded-md;
}

.cancel-tag {
  @apply bg-red-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.finish-tag {
  @apply bg-green-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.row-user {
  @apply items-center flex text-left;

  .user-block {
    @apply items-start flex flex-col my-0;
  }

  .wo-type-description {
    @apply text-slate-400 text-xs py-1;
  }

  .wo-type-name {
    @apply py-1;
  }
}
</style>