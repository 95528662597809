export const getters = {
    getComplaintsMeta($state) {
        return $state.meta
    },
    getComplaints($state) {
        if (Object.keys($state.records).length == 0) return [];
        return $state.sortOrder.map(orderId => $state.records[orderId]);
    },
    getStatuses($state) {
        return $state.statuses
    },
    getSelectedTabIndex: $state => $state.selectedTabIndex,
    getUiFlags: $state => $state.uiFlags,
    getComplaint: $state => $state.record
}