import ApiClient from './ApiClient';

class ComplaintsAPI extends ApiClient {
    constructor() {
        super('complaints', { accountScoped: true });
    }

    get(params) {
        return axios.get(`${this.url}`, {
            params
        });
    }

    status() {
        return axios.get(`${this.url}/status`)
    }

    update(id, data) {
        return axios.put(`${this.url}/${id}`, data);
    }
}


export default new ComplaintsAPI()