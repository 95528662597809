import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

const state = {
    token: '',
    userData: {},
    apiUrl: '',
    workOrder: {
        types: [],
        masters: []
    },
    uiFlags: {
        isFetching: false,
        isFetchingWorkOrderTypes: false,
        isFetchingWorkOrderMasters: false
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
