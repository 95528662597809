import types from '../../mutation-types';

export const actions = {
    getToken: async ({ commit }) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetching: true });
        try {
            
            const {
                data: {
                    token,
                    data,
                    api_url
                }
            } = await axios.get(`/open_access_token`);

            commit(types.SET_OPENACCESS_TOKEN, token);
            commit(types.SET_OPENACCESS_USER_DATA, data);
            commit(types.SET_OPENACCESS_API_URL, api_url);
            commit(types.SET_OPENACCESS_UI_FLAG, { isFetching: false });
        } catch (error) {
            console.error(error)
            commit(types.SET_OPENACCESS_UI_FLAG, { isFetching: false });
        }
    },
    getWorkOrderType: async ({commit, state}, params) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderTypes: true });
        const {
            data: {
                data
            }
        } = await axios.get(
            `${state.apiUrl}/api/work-order/work-order-master-type`,
            {
                params: {
                    current_page: 1,
                    per_page: 1000,
                    company_id: 3,
                    order_type: 'type',
                    order: 'ascending',
                },
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            }
        );

        commit(types.SET_OPENACCESS_WORK_ORDER_TYPE, data);
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderTypes: false });
    },
    getWorkOrderMaster: async ({commit, state}, type_id) => {
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderMasters: true });

        const {
            data: {
                data
            }
        } = await axios.get(
            `${state.apiUrl}/api/work-order/work-order-masters?page=1&per_page=1000&type_id=${type_id}&company_id=3&with=type,items`,
            {
                headers: {
                    Authorization: `Bearer ${state.token}`,
                },
            }
        );
        
        commit(types.SET_OPENACCESS_WORK_ORDER_MASTERS, data)
        commit(types.SET_OPENACCESS_UI_FLAG, { isFetchingWorkOrderMasters: false });
    }
}