import ApiClient from "./ApiClient";

class BroadcastApi extends ApiClient {
    constructor() {
        super('broadcasts', { accountScoped: true });
    }

    getTemplates(inboxId) {
        return axios.post(`${this.url}/${inboxId}/templates`);
    }
} 

export default new BroadcastApi();