import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

const state = {
    meta: {
        currentPage: 0,
        from: 0,
        lastPage: 0,
        perPage: 0,
        to: 0,
        total: 0
    },
    records: {},
    uiFlags: {
        isFetching: false,
        isFetchingItem: false,
        isUpdating: false,
        isUpdatingUnreadCount: false,
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
