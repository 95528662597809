import types from "../../mutation-types";
import Vue from 'vue';

export const mutations = {
    [types.SET_COMPLAINTS_UI_FLAG]($state, data) {
        $state.uiFlags = {
            ...$state.uiFlags,
            ...data,
        };
    },
    [types.CLEAR_COMPLAINTS]: $state => {
        Vue.set($state, 'records', {});
    },
    [types.SET_COMPLAINTS_META]: ($state, data) => {
        const {
            baru_count: baruCount,
            proses_count: prosesCount,
            pending_count: pendingCount,
            selesai_count: selesaiCount,
            batal_count: batalCount,
            all_count: allCount,
            current_page: currentPage
        } = data;

        Vue.set($state.meta, 'baruCount', baruCount)
        Vue.set($state.meta, 'prosesCount', prosesCount)
        Vue.set($state.meta, 'pendingCount', pendingCount)
        Vue.set($state.meta, 'selesaiCount', selesaiCount)
        Vue.set($state.meta, 'batalCount', batalCount)
        Vue.set($state.meta, 'allCount', allCount)
        Vue.set($state.meta, 'currentPage', currentPage);
    },
    [types.SET_COMPLAINTS]: ($state, data) => {
        const sortOrder = data.map(order => {
            Vue.set($state.records, order.id, {
                ...($state.records[order.id] || {}),
                ...order,
            });

            return order.id
        });

        $state.sortOrder = sortOrder;
    },
    [types.UPDATE_ALL_COMPLAINTS]: $state => {
        Object.values($state.records).forEach(item => {
            Vue.set($state.records[item.id], 'read_at', true);
        });
    },
    [types.SET_COMPLAINTS_STATUSES]: ($state, data) => {
        Vue.set($state, 'statuses', data);
    },
    [types.SET_COMPLAINTS_SELECTED_TAB_INDEX]: ($state, index) => Vue.set($state,'selectedTabIndex', index),
    [types.SET_COMPLAINT_RECORD]: ($state, data) => Vue.set($state, 'record', data)
}