import {frontendURL} from "../../../helper/URLHelper";
import BroadcastView from "./BroadcastView.vue";
export default {
  routes: [
    // {
    //   path: frontendURL('accounts/:accountId/broadcasts'),
    //   name: 'broadcasts_index',
    //   roles: ['administrator', 'agent'],
    //   component: BroadcastView,
    //   props: () => {
    //     return { inboxId: 0 };
    //   },
    // }
  ]
}
