import { frontendURL } from '../../../../helper/URLHelper';

const complaints = accountId => ({
  parentNav: 'complaints',
  routes: [
    'complaints_index',
    'workorders_index',
    'workorders_user'
  ],
  menuItems: [
    {
      icon: 'chat',
      label: 'ALL_COMPLAINT',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/complaints`),
      toStateName: 'complaints_index',
    },
    {
      icon: 'book',
      label: 'ALL_WORK_ORDERS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/work-orders`),
      toStateName: 'workorders_index',
    },
    {
      icon: 'people',
      label: 'WORK_ORDER_KU',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/work-orders/by-user`),
      toStateName: 'workorders_user',
    },
  ],
});

export default complaints;
