/* eslint arrow-body-style: 0 */
import Index from './components/Index';
import Edit from './components/ComplaintEdit';
import WorkOrderIndex from './components/WorkOrderIndex';
import WorkOrderEdit from './components/EditWorkOrder';
import WorkOrderUser from './components/WorkOrderbyUser';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/complaints'),
    name: 'complaints_index',
    roles: ['administrator', 'agent'],
    component: Index,
  },
  {
    path: frontendURL('accounts/:accountId/work-orders'),
    name: 'workorders_index',
    roles: ['administrator', 'agent'],
    component: WorkOrderIndex,
  },
  {
    path: frontendURL('accounts/:accountId/work-orders/:workOrdersId/edit'),
    name: 'workorders_edit',
    roles: ['administrator', 'agent'],
    component: WorkOrderEdit,
  },
  {
    path: frontendURL('accounts/:accountId/work-orders/by-user'),
    name: 'workorders_user',
    roles: ['administrator', 'agent'],
    component: WorkOrderUser,
  },
  {
    path: frontendURL('accounts/:accountId/complaints/:complaintId/edit'),
    name: 'complaints_edit',
    roles: ['administrator', 'agent'],
    component: Edit,
  },
];
