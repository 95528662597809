import ApiClient from './ApiClient';

class OrdersAPI extends ApiClient 
{
    constructor() {
        super('orders', { accountScoped: true });
    }

    get(params) {
        return axios.get(`${this.url}`, {
            params
        });
    }
}


export default new OrdersAPI()