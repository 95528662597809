<template>
  <div class="w-full flex flex-col min-h-screen">
    <header
      class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
    >
      <div class="flex flex-row justify-between items-center py-2">
        <div class="flex w-full py-2 px-4">
          <woot-sidemenu-icon class="mt-1" />
          <h1
            class="m-0 text-lg text-slate-900 dark:text-slate-100 whitespace-nowrap mt-1 my-0 mx-2"
          >
            Managemen Komplain
          </h1>
          <div
            class="w-full min-w-[100px] flex items-center relative mx-2 search-wrap"
          >
            <div class="flex items-center absolute h-full left-2.5">
              <fluent-icon
                icon="search"
                class="h-5 leading-9 text-sm text-slate-700 dark:text-slate-200"
              />
            </div>
            <input
              type="text"
              v-model="searchQuery"
              placeholder="Cari komplain berdasarkan nama kontak..."
              class="contact-search border-slate-100 dark:border-slate-600 pl-10 pr-10"
            />
          </div>
          <div class="flex gap-2">
            <div class="relative">
              <woot-button
                v-tooltip.right="'Filter'"
                variant="smooth"
                size="tiny"
                color-scheme="secondary"
                class="selector-button"
                icon="sort-icon"
                @click="toggleDropdown"
              />
              <div
                v-if="showActionsDropdown"
                v-on-clickaway="closeDropdown"
                class="dropdown-pane dropdown-pane--open mt-1 right-0 basic-filter"
              >
                <div class="flex flex-row items-center mb-2">
                  <span class="text-xs w-1/2">Jenis</span>
                  <select
                    v-model="selectedMass"
                    class="bg-slate-25 dark:bg-slate-700 text-xs h-6 my-0 py-0 pr-6 pl-2 w-32 border border-solid border-slate-75 dark:border-slate-600 text-slate-800 dark:text-slate-100"
                    @change="onTabChangeMass"
                  >
                    <option
                      v-for="item in complaintMassItems"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-row items-center">
                  <span class="text-xs w-1/2">Prioritas</span>
                  <select
                    v-model="selectedPriority"
                    class="bg-slate-25 dark:bg-slate-700 text-xs h-6 my-0 py-0 pr-6 pl-2 w-32 border border-solid border-slate-75 dark:border-slate-600 text-slate-800 dark:text-slate-100"
                    @change="onTabChangePriority"
                  >
                    <option
                      v-for="item in complaintPriorityItems"
                      :key="item.id"
                      :value="item.id"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <woot-button
              class="clear"
              color-scheme="success"
              icon="chat"
              data-testid="create-new-complain"
              @click="toggleCreateModal"
            >
              Tambah Komplain
            </woot-button>
          </div>
        </div>
      </div>
    </header>
    <section class="conversation-page bg-white dark:bg-slate-900 overflow-auto">
      <chat-type-tabs
        :items="tabs"
        :active-tab="selectedTabIndex"
        @chatTabChange="onTabChange"
      />
      <!-- <woot-tabs
        class="bg-white dark:bg-slate-900"
        :index="selectedTabIndex"
        :border="false"
        @change="onTabChange"
      >
        <woot-tabs-item
          class="tabs-items"
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
        />
      </woot-tabs> -->
      <div
        v-if="uiFlags.isFetching"
        class="flex justify-center items-center text-center text-slate-700 dark:text-slate-200 py-4"
      >
        <spinner /> Sedang memuat data...
      </div>
      <div v-else>
        <div
          v-if="complaints.length === 0"
          class="text-center py-4 text-gray-600"
        >
          Tidak ada data komplain.
        </div>

        <div v-else class="complaint-table overflow-auto">
          <ve-table
            max-height="calc(100vh - 10.3rem)"
            scroll-width="100rem"
            :columns="columns"
            :table-data="complaints"
            :border-around="false"
          />
        </div>
      </div>
    </section>
    <complaint
      :show="showCreateModal"
      @cancel="toggleCreateModal"
      @complaint-created="fetchComplaint"
    />
    <footer
      v-if="meta.allCount > 0"
      class="bg-white dark:bg-slate-800 h-[60px] border-t border-solid border-slate-75 dark:border-slate-700/50 flex items-center justify-between py-0 px-4"
    >
      <div class="left-aligned-wrap">
        <div class="text-xs text-slate-600 dark:text-slate-200">
          <strong>{{ firstIndex }}</strong> -
          <strong>{{ lastIndex }}</strong> of
          <strong>{{ meta.allCount }}</strong> items
        </div>
      </div>
      <div class="right-aligned-wrap">
        <div class="primary button-group pagination-button-group">
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasPrevPage"
            @click="onFirstPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
            <fluent-icon icon="chevron-left" size="18" />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasPrevPage"
            @click="onPrevPage"
          >
            <fluent-icon icon="chevron-left" size="18" />
          </woot-button>
          <woot-button size="small" variant="smooth" color-scheme="secondary">
            {{ meta.currentPage }}
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasNextPage"
            @click="onNextPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
          </woot-button>
          <woot-button
            size="small"
            variant="smooth"
            color-scheme="secondary"
            :is-disabled="!hasNextPage"
            @click="onLastPage"
          >
            <fluent-icon icon="chevron-right" size="18" />
            <fluent-icon icon="chevron-right" size="18" />
          </woot-button>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import { VeTable } from 'vue-easytable';
import { mixin as clickaway } from 'vue-clickaway';
import Spinner from 'shared/components/Spinner.vue';
import Complaint from '../../../../components/widgets/conversation/conversationBulkActions/Complaint.vue';
import { mapGetters } from 'vuex';
import ChatTypeTabs from '../../../../components/widgets/ChatTypeTabs.vue';

export default {
  components: {
    VeTable,
    Spinner,
    Complaint,
    ChatTypeTabs,
  },
  mixins: [clickaway],
  data() {
    return {
      showCreateModal: false,
      complaintMassItems: [
        { id: 2, label: 'Semua' },
        { id: 1, label: 'Massal' },
        { id: 0, label: 'Tidak' },
      ],
      complaintPriorityItems: [
        { id: 2, label: 'Semua' },
        { id: 1, label: 'Prioritas' },
        { id: 0, label: 'Tidak' },
      ],
      filteredComplaints: [],
      showActionsDropdown: false,
      // selectedTabIndex: 0,
      searchQuery: '',
      selectedMass: null,
      selectedPriority: null,
    };
  },
  watch: {
    searchQuery(newValue) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$store.dispatch('complaints/get', {
          name: newValue,
        });
      }, 500);
    },
  },
  computed: {
    ...mapGetters({
      complaints: 'complaints/getComplaints',
      statuses: 'complaints/getStatuses',
      selectedTabIndex: 'complaints/getSelectedTabIndex',
      uiFlags: 'complaints/getUiFlags',
      meta: 'complaints/getComplaintsMeta',
    }),
    tabs() {
      const tab = {
        Baru: 'baruCount',
        Proses: 'prosesCount',
        Pending: 'pendingCount',
        Selesai: 'selesaiCount',
        Batal: 'batalCount',
      };

      return Object.keys(tab).map(key => {
        let count = this.meta[tab[key]] || 0;
        return {
          key,
          name: key,
          count,
        };
      });
    },
    firstIndex() {
      return (this.meta.currentPage - 1) * this.meta.perPage + 1;
    },
    lastIndex() {
      return Math.min(
        this.firstIndex + this.meta.perPage - 1,
        this.meta.allCount
      );
    },
    hasPrevPage() {
      return this.meta.currentPage > 1;
    },
    hasNextPage() {
      return this.meta.currentPage * this.meta.perPage < this.meta.allCount;
    },
    columns() {
      return [
        {
          field: 'index',
          key: 'index',
          title: 'NO',
          width: 10,
          align: 'center',
          fixed: 'left',
          renderBodyCell: ({ rowIndex }) => {
            return (
              (this.meta.currentPage - 1) * this.meta.perPage + (rowIndex + 1)
            );
          },
        },
        {
          field: 'contact_name',
          key: 'contact_name',
          title: 'NAMA',
          fixed: 'left',
          width: 30,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const isMassComplaintWithMultipleContacts =
              row.is_mass_complaint === 1 && row.contacts.length > 1;
            const firstContactName = row.contacts[0]?.name;
            const remainingContactsCount = row.contacts.length - 1;
            const userHandling =
              row.users.length > 0
                ? row.users[0].name
                : 'Tidak ada yang menangani';

            return (
              <div class="row-user">
                <div class="user-block">
                  {isMassComplaintWithMultipleContacts ? (
                    <div>
                      {firstContactName}, +{remainingContactsCount} lainnya
                    </div>
                  ) : (
                    <div>{firstContactName}</div>
                  )}
                  <p class="user-handle">Ditangani oleh : {userHandling}</p>
                  <div class="tags">
                    {row.priority === 1 && (
                      <span class="priority-tag">Prioritas</span>
                    )}
                    {row.is_mass_complaint === 1 && (
                      <span class="mass-tag">Massal</span>
                    )}
                  </div>
                </div>
              </div>
            );
          },
        },
        // {
        //   field: 'status',
        //   key: 'status',
        //   title: 'STATUS',
        //   width: 40,
        //   align: 'center',
        //   renderBodyCell: ({ row }) => {
        //     let icon;
        //     switch (row.status_id) {
        //       case 2:
        //         icon = (
        //           <div class="tags">
        //             <span class="new-tag">Baru</span>
        //           </div>
        //         );
        //         break;
        //       case 3:
        //         icon = (
        //           <div class="tags">
        //             <span class="active-tag">Proses</span>
        //           </div>
        //         );
        //         break;
        //       case 4:
        //         icon = (
        //           <div>
        //             <div class="tags">
        //               <span class="pending-tag">Pending</span>
        //             </div>
        //             <div class="pending-status">
        //               <span>
        //                 {row.pending_status || 'Status pending tidak tersedia'}
        //               </span>
        //             </div>
        //           </div>
        //         );
        //         break;
        //       case 5:
        //         icon = (
        //           <div class="tags">
        //             <span class="finish-tag">Selesai</span>
        //           </div>
        //         );
        //         break;
        //       case 6:
        //         icon = (
        //           <div class="tags">
        //             <span class="cancel-tag">Batal</span>
        //           </div>
        //         );
        //         break;
        //       default:
        //         icon = (
        //           <svg
        //             xmlns="http://www.w3.org/2000/svg"
        //             fill="none"
        //             viewBox="0 0 24 24"
        //             strokeWidth="1.5"
        //             stroke="currentColor"
        //             class="w-6 h-6"
        //           >
        //             <path
        //               strokeLinecap="round"
        //               strokeLinejoin="round"
        //               d="M12 6v6h6"
        //             />
        //           </svg>
        //         );
        //     }
        //     return (
        //       <div
        //         class="row-user"
        //         style="display: flex; justify-content: center; align-items: center; height: 100%;"
        //       >
        //         <div class="user-block">
        //           <span>{icon}</span>
        //         </div>
        //       </div>
        //     );
        //   },
        // },
        {
          field: 'problem_name',
          key: 'problem_name',
          title: 'Masalah',
          width: 20,
          align: 'left',
          renderBodyCell: ({ row }) => (
            <div class="row-user">
              <div class="user-block">
                <p class="complaint-issue">{row.problem.name}</p>
              </div>
            </div>
          ),
        },
        {
          field: 'handling_duration',
          key: 'handling_duration',
          title: 'Lama Penanganan',
          width: 20,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const createdAt = new Date(row.created_at);
            const completedAt = row.completed_at
              ? new Date(row.completed_at)
              : null;
            const now = new Date();
            const endTime = completedAt || now;
            const durationInMilliseconds = endTime - createdAt;

            const seconds = Math.floor((durationInMilliseconds / 1000) % 60);
            const minutes = Math.floor(
              (durationInMilliseconds / 1000 / 60) % 60
            );
            const hours = Math.floor(
              (durationInMilliseconds / (1000 * 60 * 60)) % 24
            );
            const days = Math.floor(
              durationInMilliseconds / (1000 * 60 * 60 * 24)
            );

            let durationString = '';
            if (days > 0) {
              durationString += `${days} hari `;
            }
            if (hours > 0) {
              durationString += `${hours} jam `;
            }
            if (minutes > 0) {
              durationString += `${minutes} menit `;
            }

            return (
              <div class="row-user">
                <div class="user-block">
                  <p class="handling-duration">
                    {durationString || 'Baru saja'}
                  </p>
                </div>
              </div>
            );
          },
        },
        {
          field: 'complaint_date',
          key: 'complaint_date',
          title: 'Tanggal',
          width: 60,
          align: 'left',
          renderBodyCell: ({ row }) => {
            const dateOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
            const complaintDate = new Date(row.complaint_date);
            const localDate = complaintDate.toLocaleDateString(
              'id-ID',
              dateOptions
            );
            const time = row.complaint_time;
            const timePart = time.split('T')[1].split('.')[0];
            const formattedTime = timePart.substring(0, 5);

            return (
              <div class="row-user">
                <div class="user-block">
                  <p class="complaint-date">{localDate}</p>
                  <p class="complaint-date">{formattedTime}</p>
                </div>
              </div>
            );
          },
        },
        {
          field: 'aksi',
          key: 'aksi',
          title: 'Aksi',
          width: 10,
          fixed: 'right',
          renderBodyCell: ({ row }) => (
            <div>
              <router-link
                to={`/app/accounts/${this.$route.params.accountId}/complaints/${row.id}/edit`}
              >
                Edit
              </router-link>
            </div>
          ),
        },
      ];
    },
  },
  methods: {
    findStatusByTabKey(tab) {
      switch (tab) {
        case 'Baru':
          return 2;
        case 'Proses':
          return 3;
        case 'Pending':
          return 4;
        case 'Selesai':
          return 5;
        default:
          return 6;
      }
    },
    toggleCreateModal() {
      this.showCreateModal = !this.showCreateModal;
    },
    toggleDropdown() {
      this.showActionsDropdown = !this.showActionsDropdown;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    onTabChangeMass() {
      this.$store.dispatch('complaints/get', {
        status_id: this.findStatusByTabKey(this.selectedTabIndex),
        priority: this.selectedPriority,
        name: this.searchQuery,
        is_mass_complaint: this.selectedMass,
      });
    },
    onTabChangePriority() {
      this.$store.dispatch('complaints/get', {
        status_id: this.findStatusByTabKey(this.selectedTabIndex),
        priority: this.selectedPriority,
        name: this.searchQuery,
        is_mass_complaint: this.selectedMass,
      });
    },
    onTabChange(index) {
      this.$store.dispatch('complaints/setSelectedTabIndex', index);
      this.$store.dispatch('complaints/get', {
        status_id: this.findStatusByTabKey(index),
      });
    },
    onFirstPage() {
      this.meta.currentPage = 1;
      this.$store.dispatch('complaints/get', {
        page: this.meta.currentPage,
        status_id: this.findStatusByTabKey(this.selectedTabIndex),
        priority: this.selectedPriority,
        name: this.searchQuery,
      });
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.meta.currentPage--;

        this.$store.dispatch('complaints/get', {
          page: this.meta.currentPage,
          status_id: this.findStatusByTabKey(this.selectedTabIndex),
          priority: this.selectedPriority,
          name: this.searchQuery,
        });
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.meta.currentPage++;

        this.$store.dispatch('complaints/get', {
          page: this.meta.currentPage,
          status_id: this.findStatusByTabKey(this.selectedTabIndex),
          priority: this.selectedPriority,
          name: this.searchQuery,
        });
      }
    },
    onLastPage() {
      this.meta.currentPage = Math.ceil(this.meta.allCount / this.meta.perPage);

      this.$store.dispatch('complaints/get', {
        page: this.meta.currentPage,
        status_id: this.findStatusByTabKey(this.selectedTabIndex),
        priority: this.selectedPriority,
        name: this.searchQuery,
      });
    },
    toggleDropdown() {
      this.showActionsDropdown = !this.showActionsDropdown;
    },
    closeDropdown() {
      this.showActionsDropdown = false;
    },
    fetchComplaint() {
      this.$store.dispatch('complaints/get');
    },
  },
  mounted() {
    // this.fetchStatus();

    this.$store.dispatch('complaints/fetchStatus');
    this.fetchComplaint();
  },
};
</script>

<style scoped>
.conversation-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
}

.complaint-table {
  margin: 0;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
}

.tabs-items {
  margin-right: 15px;
}

.mass-tag {
  @apply bg-red-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.priority-tag {
  @apply bg-green-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.settings--tabs ::v-deep .tabs {
  @apply p-0;
}

.new-tag {
  @apply bg-slate-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.pending-tag {
  @apply bg-yellow-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.active-tag {
  @apply bg-yellow-400 text-white text-xs py-0.5 px-3 rounded-md;
}

.cancel-tag {
  @apply bg-red-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.finish-tag {
  @apply bg-green-600 text-white text-xs py-0.5 px-3 rounded-md;
}

.row-user {
  @apply items-center flex text-left;

  .user-block {
    @apply items-start flex flex-col my-0;
  }

  .complaint-issue {
    @apply text-sm py-1;
  }

  .complaint-date {
    @apply text-sm py-1;
  }

  .handling-duration {
    @apply text-sm py-1;
  }

  .user-handle {
    @apply text-slate-400 text-xs italic py-1;
  }
}

.tags {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pending-status {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.search-wrap {
  .contact-search {
    @apply pl-9 pr-[3.75rem] text-sm w-full h-[2.375rem] m-0;
  }
}

.basic-filter {
  @apply w-52 p-4 top-6;
}
</style>