import BroadcastApi from "../../../api/broadcast"
import types from '../../mutation-types';

export const state = {
    templates: []
}

export const getters = {
    getTemplates($state) {
        return $state.templates
    }
}

export const actions = {
    getTemplates: async ({ commit }, inboxId) => {
        try {
            const { data } = await BroadcastApi.getTemplates(inboxId);
            console.log("Get Templates : ", data)
            commit(types.SET_MESSAGE_TEMPLATES, data)
        } catch(error) {
            console.error(error)
        }
    }
}

export const mutations = {
    [types.SET_MESSAGE_TEMPLATES]($state, data) {
        $state.templates = data
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}