<template>
  <woot-modal :show.sync="show" :on-close="onCancel">
    <div class="h-auto overflow-auto flex flex-col mb-30 pb-10">
      <woot-modal-header :header-title="$t('BULK_ACTION.COMPLAINT.COMPLAINT_HEADER')" />
      <div class="mt-4 px-8">
        <div class="gap-2 flex flex-row">
          <div class="w-[50%]">
            <label>
              <input class="checkbox pr-1" type="checkbox" v-model="prioritySelected" /> Prioritas
            </label>
          </div>
          <div class="w-[50%]">
            <label>
              <input class="checkbox pr-1" type="checkbox" v-model="massSelected" /> Massal
            </label>
          </div>
        </div>
        <div v-if="massSelected">
          <div class="w-full py-2">
            <label for="massComplaintInput" class="mb-2">Komplain Massal</label>
            <input type="text" class="text mb-0" v-model="massComplaint" list="massComplaintOptionsList"
              id="massComplaintInput" placeholder="Pilih atau ketik komplain massal">
            <datalist id="massComplaintOptionsList">
              <option v-for="option in massComplaintOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </datalist>
          </div>
        </div>
        <div class="gap-2 flex flex-row">
          <div class="w-full" v-if="listConversations.length > 0">
            <label class="mb-2">Nama Kontak
            </label>
            <input class="text" type="text" disabled :value="displayContactName"></input>
          </div>
          <div class="w-full" v-else>
            <label class="mb-1">Pelanggan</label>
            <multiselect v-model="pelangganSelected" :max-height="160" :options="pelanggans" track-by="id_order"
                label="nama_pelanggan" :multiple="true" :close-on-select="true" :clear-on-select="true"
                @search-change="onSelectSearch"
                :hide-selected="true" placeholder="Pilih pelanggan.."
                :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
                :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')" class="custom-multiselect" />
          </div>
          
        </div>
        <div class="gap-2 flex flex-row">
          <div class="w-[50%]">
            <label class="mb-2">Pilih Masalah</label>
            <select :max-height="160" v-model="issueSelected">
              <option disabled value="">Pilih</option>
              <option v-for="issue in issues" :key="issue.value" :value="issue.value">{{ issue.label }}</option>
            </select>
          </div>
          <div class="w-[50%]">
            <label class="mb-2"> Komplain Melalui
            </label>
            <select :max-height="160" v-model="sourceSelected">
              <option disabled value="">Pilih</option>
              <option v-for="source in sources" :value="source.value">{{ source.label }}</option>
            </select>
          </div>
        </div>
        <div class="gap-2 flex flex-row">
          <div class="w-[50%]">
            <label class="mb-2">
              Tanggal Komplain
            </label>
            <input type="date" v-model="currentDate" class="mb-0 datepicker"></input>
          </div>
          <div class="w-[50%]">
            <label class="mb-2"> Jam Komplain
            </label>
            <input type="time" v-model="currentTime" class="mb-0 timepicker" />
          </div>
        </div>
        
        <div v-if="issueSelected === 8" class="flex">
          <div class="w-full">
            <input class="text" type="text" v-model="otherIssue" :placeholder="'Masukkan keterangan masalah..'"></input>
          </div>
        </div>
        <div v-if="issueSelected === 2">
          <label class="mb-2">
            Jangka Waktu Mati
          </label>
          <woot-date-range-picker class="w-full" show-range :confirm-text="'Pilih'" :value="customDateRange"
            @change="onChangeRange" />
        </div>
        <div>
          <label class="mb-2">Keterangan</label>
          <textarea v-model="message" class="min-h-[5rem]" type="text" :placeholder="'Masukkan Keterangan'" />
        </div>
        <div class="flex flex-col items-center">
          <label class="self-start">Tambah Foto</label>
          <input type="file" @change="onFileChange" multiple accept="image/*" class="my-3" />
        </div>
        <div class="flex flex-row justify-end gap-2 pt-5 px-0 w-full">
          <woot-button type="submit" @click="saveComplaint">
            Simpan
          </woot-button>
          <button class="button clear" @click.prevent="onCancel">
            Batal
          </button>
        </div>
      </div>
    </div>
  </woot-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import WootDateTimePicker from 'dashboard/components/ui/DateTimePicker.vue';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
import alertMixin from '../../../../../shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      massSelected: false,
      prioritySelected: false,
      message: '',
      massComplaint: '',
      sourceSelected: 3,
      issueSelected: 1,
      otherIssue: '',
      currentDate: '',
      currentTime: '',
      contactId: '',
      contactData: '',
      pelangganSelected: [],
      contactDetail: '',
      customDateRange: [],
      massComplaintOptions: [],
      uploadedImages: [],
      sources: [],
      issues: [],
      listConversations: [],
      orderQuery: {
        filter: 'all',
      },
    };
  },
  components: {
    WootDateTimePicker,
    WootDateRangePicker,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    conversationCount: {
      type: Number,
      default: 0,
    },
    conversation: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      currentUserID: 'getCurrentUserID',
      records: 'contacts/getContacts',
      orderMeta: 'orders/getOrdersMeta',
      orders: 'orders/getOrders',
    }),

    pelanggans() {
      return Object.values(this.orders);
    },

    displayContactName() {
      if (this.conversation.length === 1) {
        if (this.listConversations.length > 0) {
          return this.listConversations[0].contact.name;
        }

        return 'Waiting...';
      } else if (this.conversation.length > 1) {
        if (this.listConversations.length > 0) {
          return `${this.listConversations[0].contact.name}, +${
            this.conversation.length - 1
          } lainnya`;
        }

        return 'Waiting...';
      } else {
        return '';
      }
    },

    isMassComplaint() {
      return this.massSelected != false;
    },
  },

  methods: {
    onSelectSearch(query) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        this.$store.dispatch('orders/get', {
          filter: 'all',
          search: query,
        });
      }, 500);
    },
    resetForm() {
      let form = {
        massSelected: false,
        prioritySelected: false,
        message: '',
        massComplaint: '',
        sourceSelected: 3,
        issueSelected: 1,
        otherIssue: '',
        currentDate: '',
        currentTime: '',
        contactId: '',
        contactData: '',
        pelangganSelected: [],
        contactDetail: '',
        customDateRange: [],
        massComplaintOptions: [],
        uploadedImages: [],
        listConversations: [],
      };

      Object.keys(form).forEach(element => {
        this[element] = form[element];
      });

      this.setDefaultDateAndTime();
    },
    onChangeRange(value) {
      this.customDateRange = value;
    },
    onCancel() {
      this.$emit('cancel');
    },
    onFileChange(event) {
      const files = event.target.files;
      this.uploadedImages = [];
      const promises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        promises.push(this.convertToBase64(file));
      }

      Promise.all(promises).then(base64Files => {
        this.uploadedImages = base64Files;
      });
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => {
          reject(error);
        };
      });
    },
    async fetchDetail() {
      try {
        const contactId = this.contactId;
        const currentRecord = this.records.find(
          record => record.id === contactId
        );

        if (currentRecord) {
          this.contactDetail = currentRecord;
        }
      } catch (error) {
        console.error('Error fetching record detail:', error);
      }
    },
    async fetchRecord(contactId) {
      try {
        const currentRecord = this.records.find(
          record => record.id === contactId
        );

        if (currentRecord) {
          this.contactData = currentRecord;
        }
      } catch (error) {
        console.error('Error fetching record data:', error);
      }
    },
    async fetchConversations() {
      try {
        const response = await axios.get('/complaint_conversations', {
          params: { ids: this.conversation },
        });
        this.listConversations = response.data;
        const bulkListConversation = this.listConversations.filter(conv =>
          this.conversation.includes(conv.display_id)
        );

        this.contactIds = bulkListConversation.map(conv => conv.contact_id);
        if (this.contactIds.length > 0) {
          this.contactId = this.contactIds[0];
          this.fetchDetail();
        }
      } catch (error) {
        console.error('Error fetching conversation data:', error);
      }
    },
    async fetchIssues() {
      try {
        const response = await axios.get('/problems');
        this.issues = response.data.map(problem => ({
          value: problem.id,
          label: problem.name,
        }));
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    },
    async fetchSources() {
      try {
        const response = await axios.get('/complaint_sources');
        this.sources = response.data.map(source => ({
          value: source.id,
          label: source.name,
        }));
      } catch (error) {
        console.error('Error fetching sources data:', error);
      }
    },
    async saveComplaint() {
      try {
        const image = JSON.stringify(this.uploadedImages);
        const body = {
          complaint_date: this.currentDate,
          complaint_source_id: this.sourceSelected,
          complaint_time: this.currentTime,
          description: this.message,
          is_mass_complaint: this.isMassComplaint ? 1 : 0,
          mass_complaint_description: this.isMassComplaint
            ? this.massComplaint
            : null,
          priority: this.prioritySelected ? 1 : 0,
          problem_id: this.issueSelected,
          status_id: 2,
          offline_range_start_date: this.customDateRange[0],
          offline_range_end_date: this.customDateRange[1],
          work_order_id: null,
          medias: image,
          conversation_ids: this.conversation,
          other_issue: this.otherIssue,
          pelanggans: this.pelangganSelected,
        };

        const {
          data: { data },
        } = await this.$store.dispatch('complaints/create', body);

        this.$emit('complaint-created');

        this.resetForm();

        let action = null;
        if (data.length == 1) {
          action = {
            type: 'link',
            to: `/app/accounts/${data[0].account_id}/complaints/${data[0].id}/edit`,
            message: this.$t('NEW_CONVERSATION.FORM.GO_TO_CONVERSATION'),
          };
        }

        this.showAlert('Berhasil membuat komplain.', action);
        this.$emit('cancel');
      } catch (error) {
        console.error('Error saving complaint:', error);
        this.showAlert('Terjadi kesalahan saat membuat komplain.');
      }
    },

    setDefaultDateAndTime() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      this.currentDate = `${year}-${month}-${day}`;
      const hours = String(today.getHours()).padStart(2, '0');
      const minutes = String(today.getMinutes()).padStart(2, '0');
      this.currentTime = `${hours}:${minutes}`;
    },
  },

  mounted() {
    this.fetchConversations();
    this.fetchIssues();
    this.fetchSources();
    this.setDefaultDateAndTime();

    if (this.listConversations.length == 0) {
      this.$store.dispatch('orders/get', this.orderQuery);
    }
  },
};
</script>
