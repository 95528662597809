<template>
  <div>
    <div class="gap-2 flex flex-row mt-5">
      <div class="text-nowrap flex flex-col w-full">
        <label class="mb-1">Jenis WO</label>
        <multiselect
          v-model="workOrderTypeSelected"
          :options="workOrder.types"
          track-by="id"
          label="type"
          :close-on-select="true"
          :clear-on-select="true"
          :hide-selected="true"
          placeholder="Pilih jenis WO.."
          @select="onWorkOrderTypeChanged"
          @remove="onWorkOrderTypeChanged"
        />
      </div>
    </div>

    <div
      v-if="uiFlags.isFetchingWorkOrderMasters"
      class="w-full flex justify-center items-center text-center text-slate-700 dark:text-slate-200 py-4"
    >
      <spinner /> Sedang memuat data...
    </div>

    <div
      v-else-if="workOrder.masters.length === 0"
      class="text-center py-4 text-gray-600"
    >
      Tidak ada data
    </div>
    <div class="gap-2 flex flex-row" v-else-if="showWorkOrderMaster">
      <div class="infinite-list-wrapper position-relative w-full">
        <ul
          infinite-scroll-disabled="disabled"
          class="list-group mt-3"
          style="
            max-height: 400px;
            overflow-y: scroll;
            margin-left: 0 !important;
          "
        >
          <li
            v-for="item in workOrder.masters"
            :key="item.id"
            class="list-group-item d-flex justify-content-between px-2 py-4 click-list"
            style=""
            @click="() => onWorkOrderMasterChanged(item)"
          >
            <div class="d-flex align-items-center">
              <span class="mx-2 text-mobile">{{ item.name }}</span>
            </div>
            <small
              ><i class="el-icon-timer"></i>
              {{ item.working_time }} menit</small
            >
          </li>
        </ul>
      </div>
    </div>

    <div v-else>
      <woot-button @click="toggleWorkOrderMaster" class="button">
        Tampilkan work order master
      </woot-button>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Spinner from 'shared/components/Spinner.vue';
import WootButton from '../../../../components/ui/WootButton.vue';

export default {
  components: {
    Spinner,
    WootButton,
  },
  data() {
    return {
      workOrderTypeSelected: '',
      showWorkOrderMaster: true,
    };
  },
  props: {
    hideWoMasterOnChanged: {
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      workOrder: 'openaccess/getWorkOrder',
      uiFlags: 'openaccess/getUiFlags',
      token: 'openaccess/getToken',
    }),
  },
  watch: {
    token(newValue) {
      this.$store.dispatch('openaccess/getWorkOrderType');
    },
  },
  methods: {
    onWorkOrderTypeChanged(value) {
      this.workOrderTypeSelected = value;

      this.$store.dispatch('openaccess/getWorkOrderMaster', value.id);
      this.$emit('work-order-type', value);
    },
    onWorkOrderMasterChanged(value) {
      this.$emit('work-order-master', value);

      if (this.hideWoMasterOnChanged) {
        this.toggleWorkOrderMaster();
      }
    },

    toggleWorkOrderMaster() {
      this.showWorkOrderMaster = !this.showWorkOrderMaster;
    },
  },
  mounted() {
    if (this.token) this.$store.dispatch('openaccess/getWorkOrderType');
  },
};
</script>

<style>
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item {
  background-color: hsl(200 7% 8.8% / var(--tw-bg-opacity));
  border: 1px solid #697076;
  padding: 0.75rem 1.25rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
</style>